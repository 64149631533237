html {
    background: #000000;
    font-family: 'VT323', monospace;
    -webkit-font-smoothing: antialiased;
    padding: 20px 0;
}

.band {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;

}

@media only screen and (min-width: 650px) {
    .band {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (min-width: 1200px) {
    .band {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

.card {
    min-width: 320px;
    height: 100px;
    background: black;
    border-bottom: 1px dashed green;
    display: grid;
    text-decoration: none;
    color: green;
    position: relative;
    font-size: small;
    top: 0;
    transition: all .1s ease-in;
}

.card:hover {
    top: -2px;
    color: gold;
    border-bottom: 1px dashed gold;
}

.card .thumb {
    grid-column: 1 / 2;
    grid-row: 1;
    height: 100px;
    width: 100px;
    background-size: cover;
    background-position: center center;
    background-color: green;
}

.card:hover .thumb {
    background-color: gold;
}

.card article {
    grid-row: 1;
    grid-column: 2;
    padding: 0 0 0 9px;
    justify-content: space-between;
    flex-direction: row;

}

.card p {
}

/* typography */
.card h1 {
    font-size: 22px;
    margin: 0 0 3px 0;
}

.card span {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: .05em;
    margin: 2em 0 0 0;
}

.card textarea {
    background-color: rgba(5, 5, 5, 0.91);
    border: 1px dashed yellow;
    width: 250px;
    color: red;
}
